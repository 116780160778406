import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { calculateRem } from '../../utils/javascript-styles'

const StyledTitle = styled.h3`
  color: ${ props => props.theme.coralRed };
  font-family: "Satisfy", cursive;
  font-size: ${ calculateRem(76) };
  display: block;
  text-align: center;
  margin: 0;
`

const Title = ({ alternate, title }) => (
  <StyledTitle color={alternate}>{title}</StyledTitle>
)

Title.defaultProps = {
  alternate: false,
}
Title.propTypes = {
  alternate: PropTypes.bool,
  title: PropTypes.string.isRequired,
}

export default Title
