import React from "react"
import { ThemeProvider } from "styled-components"
import { Row, Col } from "react-styled-flexboxgrid"
import PropTypes from "prop-types"
import themeObj from "../../utils/theme"
import Header from "../Header"
import Footer from "../Footer"

const ComposedLayout = ({ children }) => (
  <Row>
    <Col xs={12}>
      <Header />
      {children}
      <Footer />
    </Col>
  </Row>
)

const Layout = ({ children }) => (
  <React.Fragment>
    <ThemeProvider theme={themeObj}>
      <ComposedLayout>{children}</ComposedLayout>
    </ThemeProvider>
  </React.Fragment>
)

ComposedLayout.propTypes = {
  children: PropTypes.node.isRequired,
}
Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
