import React, { Component } from "react"

import MediaQuery from "react-responsive"
import cuid from "cuid"
import Navigation from "./Navigation"
import MobileNavigation from "./MobileNavigation"

class NavContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      yAxis: null,
      navMenu: [
        {
          id: cuid(),
          route: "",
          title: "Home",
          anchor: "",
          isActive: false,
        },

        {
          id: cuid(),
          route: "play-areas",
          title: "Play Areas",
          anchor: "play-areas",
          isActive: false,
        },

        // {
        //   id: cuid(),
        //   route: "snow-tubing",
        //   title: "Snow Tubing",
        //   anchor: "snow-tubing",
        //   isActive: false,
        // },
        {
          id: cuid(),
          route: "snow-effects",
          title: "Snow Effects",
          anchor: "snow-effects",
          isActive: false,
        },
        {
          id: cuid(),
          route: "contact",
          title: "Contact",
          anchor: "contact",
          isActive: false,
        },
      ],
      mobileMenuOpen: false,
      activeLink: "home",
    }
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
  }
  componentWillMount() {
    if (typeof window !== `undefined`) {
      window.removeEventListener("scroll", this.handleScroll)
    }
  }

  handleScroll = () => {
    const yAxis = window.pageYOffset
    this.setState({
      yAxis,
    })
  }

  openMobileMenu = () => {
    this.setState({
      mobileMenuOpen: !this.state.mobileMenuOpen,
    })
  }

  render() {
    const { navMenu, activeLink, mobileMenuOpen, yAxis } = this.state
    return (
      <section>
        <MediaQuery query="(max-device-width: 1224px)">
          <MobileNavigation
            navMenu={navMenu}
            mobileMenuOpen={mobileMenuOpen}
            controlFunc={this.openMobileMenu}
          />
        </MediaQuery>
        <MediaQuery query="(min-device-width: 1225px)">
          <Navigation yAxis={yAxis} navMenu={navMenu} activeLink={activeLink} />
        </MediaQuery>
      </section>
    )
  }
}

export default NavContainer
