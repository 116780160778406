import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Grid, Row, Col } from "react-styled-flexboxgrid"
import Base from "./Base"

const defaultProps = {
  centered: false,
}

const propTypes = {
  children: PropTypes.node.isRequired,
  centered: PropTypes.bool,
}
const SectionAlternate = styled(Base)`
  background-color: ${props => props.theme.oil};
  min-height: ${props =>
    props.backgroundHeight ? props.backgroundHeight + "px" : "initial"};
  text-align: ${props => (props.textCentered ? "center" : "initial")};
  h1,
  h2,
  h3,
  h4,
  h5,
  blockquote {
    color: white;
  }

  strong {
    color: ${props => props.theme.oil};
  }
  p {
    color: ${props => props.theme.fountainBlue};
  }
`
const WhiteSpace = styled.hr`
  background-color: transparent;
  margin-top: 2rem;
`
const Alternate = props => {
  const { children, centered, backgroundHeight } = props
  return (
    <SectionAlternate
      textCentered={centered}
      backgroundHeight={backgroundHeight}
    >
      <Grid>
        <Row>
          <Col xs={12}>
            {props.backgroundHeight && <WhiteSpace />}
            {children}
          </Col>
        </Row>
      </Grid>
    </SectionAlternate>
  )
}

Alternate.defaultProps = defaultProps
Alternate.propTypes = propTypes

export default Alternate
