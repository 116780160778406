import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Container = styled.section`
  max-width: ${ props => props.maxWidth };
`

const MaxWidth = ({ maxWidth, children }) => (
  <Container maxWidth={maxWidth}>{children}</Container>
)

MaxWidth.defaultProps = {
  maxWidth: 972,
}

MaxWidth.propTypes = {
  maxWidth: PropTypes.number,
  children: PropTypes.node.isRequired,
}

export default MaxWidth
