import React from 'react';
import PropTypes from 'prop-types';
import SvgIconBase from './SvgIconBase';

const IconMobileMenu = ({ fillColor, width }) => {
  return (
    <SvgIconBase viewBox="0 0 128 80.03" width={width} fill={fillColor}>
      <path
        d="M76.14,26h47.09c3.31,0,4.76,1.48,4.77,4.82,0,2.29,0,4.58,0,6.87A4,4,0,0,1,123.71,42H28.41C25.61,42,24,40.4,24,37.57c0-2.33,0-4.66,0-7,0-3,1.56-4.56,4.53-4.56Z"
        transform="translate(0 -26)"
      />
      <path
        d="M68,106H28.8c-3.32,0-4.79-1.48-4.78-4.81,0-2.29,0-4.58,0-6.87A4,4,0,0,1,28.27,90h79.44a4,4,0,0,1,4.29,4.3q0,3.68,0,7.37a4,4,0,0,1-4.38,4.32Z"
        transform="translate(0 -26)"
      />
      <path
        d="M60,58q15.66,0,31.34,0C94.49,58,96,59.54,96,62.7c0,2.33,0,4.66,0,7A4,4,0,0,1,91.73,74H28.29A4,4,0,0,1,24,69.7c0-2.46,0-4.91,0-7.37A4,4,0,0,1,28.41,58H60Z"
        transform="translate(0 -26)"
      />
      <path
        d="M16,34.05c0,1.33,0,2.67,0,4a3.94,3.94,0,0,1-4,4q-4,.06-8,0a3.93,3.93,0,0,1-4-4Q0,34,0,29.93A3.89,3.89,0,0,1,4,26Q8,26,12.08,26a3.93,3.93,0,0,1,3.93,4C16,31.38,16,32.72,16,34.05Z"
        transform="translate(0 -26)"
      />
      <path
        d="M16,66c0,1.33,0,2.66,0,4A4,4,0,0,1,12,74c-2.66,0-5.32,0-8,0A3.92,3.92,0,0,1,0,70c0-2.66,0-5.32,0-8a3.94,3.94,0,0,1,4.05-4c2.62,0,5.24,0,7.85,0A4,4,0,0,1,16,62.13C16,63.42,16,64.71,16,66Z"
        transform="translate(0 -26)"
      />
      <path
        d="M8.05,90c1.33,0,2.67,0,4,0a3.94,3.94,0,0,1,4,4q.06,4,0,8.1a3.91,3.91,0,0,1-3.9,3.9C9.4,106,6.69,106,4,106a3.93,3.93,0,0,1-4-4Q0,98,0,93.92A3.92,3.92,0,0,1,3.94,90C5.31,90,6.68,90,8.05,90Z"
        transform="translate(0 -26)"
      />
    </SvgIconBase>
  );
};

IconMobileMenu.defaultProps = {
  fillColor: '#15133f',
  width: 30
};

IconMobileMenu.propTypes = {
  fillColor: PropTypes.string,
  width: PropTypes.number
};

export default IconMobileMenu;
