import React from "react"
import styled from "styled-components"
import { Grid, Row, Col } from "react-styled-flexboxgrid"
import { Link } from "gatsby"
import Logo from "../../images/logo.svg"

import { calculateRem } from "../../utils/javascript-styles"

const StyledFooter = styled.footer`
  background-color: ${props => props.theme.oil};
  color: white;
  padding: ${calculateRem(20)};
  text-align: center;
  font-family: "Raleway", sans-serif;
`
const StyledFooterLogo = styled.img`
  display: block;
  width: ${calculateRem(150)};
  margin: 0 auto;
  @media screen and (max-width: 39.9375em) {
    display: none;
  }
`
const TopRow = styled(Row)`
  padding: ${calculateRem(25)};
`
const LeftP = styled.p`
  text-align: left;
  margin-bottom: 0;

  @media screen and (max-width: 39.9375em) {
    text-align: center;
    margin-bottom: 0;
  }
`

const RigthP = styled.p`
  text-align: right;
  margin-bottom: 0;
  @media screen and (max-width: 39.9375em) {
    display: none;
  }
`

const Footer = () => (
  <StyledFooter>
    <Grid>
      <TopRow>
        <Col xs={12} md={4}>
          <LeftP>© Iceman LLC. All rights reserved.</LeftP>
        </Col>
        <Col xs={12} md={4}>
          <Link to="/">
            <StyledFooterLogo src={Logo} alt="Logo" />
          </Link>
        </Col>
        <Col xs={12} md={4}>
          <RigthP>(770) 599-2862</RigthP>
          <RigthP>snow@myiceman.com</RigthP>
        </Col>
      </TopRow>
    </Grid>
  </StyledFooter>
)

export default Footer
