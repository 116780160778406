import styled from 'styled-components'
import { calculateRem } from '../../utils/javascript-styles'

const LeadText = styled.p`
  font-size: ${ calculateRem(18) };
  text-align: center;
  max-width: ${ calculateRem(972) };
  margin: 0 auto 2rem auto;
`

export default LeadText
