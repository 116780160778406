import styled from "styled-components"
import { calculateRem } from "../../utils/javascript-styles"

const Base = styled.section`
  padding: 5rem 1rem;
  h1,
  h2,
  h3 {
    font-family: "Patua One";
  }
  h4,
  h5,
  p,
  a,
  span {
    font-family: "Raleway", sans-serif;
  }
  h1 {
    font-size: ${calculateRem(58)};
  }
  h2 {
    font-size: ${calculateRem(48)};
  }
  h3 {
    font-size: ${calculateRem(38)};
  }
  h4 {
    font-size: ${calculateRem(28)};
  }
  /* Small only */
  @media screen and (max-width: 39.9375em) {
    h1 {
      font-size: ${calculateRem(48)};
    }
    h2 {
      font-size: ${calculateRem(38)};
    }
    h3 {
      font-size: ${calculateRem(28)};
    }
    h4 {
      font-size: ${calculateRem(18)};
    }
  }
`

export default Base
