import React from "react"
import PropTypes from "prop-types"

import SvgIconBase from "./SvgIconBase"

const IconWebApp = ({ fillColor, width }) => {
  return (
    <SvgIconBase
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 128 90.71"
      width={width}
      fill={fillColor}
    >
      <path
        d="M64,0h54c3.34,0,5.14,1.83,5.14,5.16q0,35.17,0,70.35c0,3.58-1.86,5.42-5.46,5.42H10.16c-3.53,0-5.4-1.85-5.4-5.35q0-35.23,0-70.47C4.77,1.88,6.63,0,9.83,0ZM64,6h0Q37.64,6,11.33,6C10.1,6,9.7,6.39,9.7,7.62q0,28.42,0,56.86c0,3,0,6,0,9,0,1,.32,1.46,1.32,1.4.3,0,.6,0,.9,0h82c7.53,0,15.06,0,22.59,0,1.27,0,1.84-.41,1.69-1.71a15.49,15.49,0,0,1,0-1.67q0-31.76,0-63.53c0-2,0-2-2-2Z"
        transform="translate(0 0)"
      />
      <path
        d="M64,90.71H5.32c-.56,0-1.11,0-1.67,0A3.79,3.79,0,0,1,0,86.93a3.58,3.58,0,0,1,3.55-3.76c5-.09,10-.08,15-.08q22.46,0,44.92,0h47.88c4.19,0,8.38,0,12.58,0a3.72,3.72,0,0,1,3.85,2.58,3.8,3.8,0,0,1-3.74,5c-5.35,0-10.7,0-16,0Zm.07-4.65c-5.6,0-11.2,0-16.8,0-.7,0-1.76-.53-2,.69a4.34,4.34,0,0,0,2,.67c11.24,0,22.48,0,33.72,0a3,3,0,0,0,1.13-.13,1.1,1.1,0,0,0,.56-.7c0-.13-.36-.45-.6-.51a4.86,4.86,0,0,0-1.15,0Z"
        transform="translate(0 0)"
      />
      <path
        d="M63.85,31.88c-15.74,0-31.49,0-47.23,0-1.23,0-1.61-.33-1.6-1.58q.09-8.91,0-17.84c0-1.14.34-1.44,1.46-1.44q47.43,0,94.85,0c1.23,0,1.61.33,1.6,1.58-.06,5.95,0,11.9,0,17.84,0,1.14-.35,1.45-1.47,1.45Q87.67,31.86,63.85,31.88Z"
        transform="translate(0 0)"
      />
      <path
        d="M91.11,58.07c-6.72,0-13.43,0-20.15,0-1.07,0-1.51-.21-1.5-1.4.06-6,.05-12.07,0-18.1,0-1,.21-1.47,1.34-1.47q20.28,0,40.56,0c1.13,0,1.58.27,1.57,1.51-.06,6,0,12.06,0,18.1,0,1.09-.37,1.37-1.41,1.36C104.72,58.05,97.92,58.07,91.11,58.07Z"
        transform="translate(0 0)"
      />
      <path
        d="M36.77,58.08H17.13c-.84,0-2,.17-2-1S16.24,56,17.09,56H56.23c.85,0,2.07-.26,2,1.07s-1.17,1-2,1H36.77Z"
        transform="translate(0 0)"
      />
      <path
        d="M36.82,48.58H17.32a1.7,1.7,0,0,1-.64,0c-.54-.29-1.45-.57-1.51-1-.13-.92.74-1.12,1.5-1.12,4,0,8,0,11.94,0h28c.81,0,1.72.07,1.73,1.07s-.93,1-1.73,1H36.82Z"
        transform="translate(0 0)"
      />
      <path
        d="M36.72,65.48H56.6c.8,0,1.73,0,1.68,1.06s-1,1-1.76,1H38.82q-10.58,0-21.16,0a2.93,2.93,0,0,1-1.15,0c-.52-.25-1.3-.63-1.35-1-.11-.86.68-1,1.42-1H36.72Z"
        transform="translate(0 0)"
      />
      <path
        d="M36.63,60.82H56.12a4.06,4.06,0,0,1,1.4.09,1.21,1.21,0,0,1,.69.86,1.28,1.28,0,0,1-.68.88,2.67,2.67,0,0,1-1.14.08H17.66a4.79,4.79,0,0,1-1.52-.06c-.41-.15-.71-.59-1.06-.89.35-.32.66-.85,1-.9a30.51,30.51,0,0,1,3.45-.07Z"
        transform="translate(0 0)"
      />
      <path
        d="M91.2,62.73H71.44a2.23,2.23,0,0,1-1-.07c-.31-.18-.69-.57-.69-.87s.37-.69.68-.9.58-.07.88-.07h39.9c.17,0,.39-.07.51,0,.41.28.78.61,1.17.93-.38.31-.76.89-1.15.91-2.31.09-4.62.06-6.93.06H91.2Z"
        transform="translate(0 0)"
      />
      <path
        d="M36.74,41.85H56.23c.34,0,.75-.12,1,0a6.85,6.85,0,0,1,1.11,1c-.36.3-.7.84-1.07.86-1.62.11-3.24.08-4.87.08l-35.51,0a2.18,2.18,0,0,1-1-.1c-.33-.19-.8-.62-.75-.85a1.73,1.73,0,0,1,.84-.94,2.22,2.22,0,0,1,1-.06Z"
        transform="translate(0 0)"
      />
      <path
        d="M36.77,53.26h-19a4.08,4.08,0,0,1-1.4,0,6.23,6.23,0,0,1-1.31-.92,4.58,4.58,0,0,1,1.3-.91,7.59,7.59,0,0,1,1.91,0H56.38c.26,0,.57-.09.76,0a11.16,11.16,0,0,1,1.13.93c-.37.32-.71.85-1.11.9a28.43,28.43,0,0,1-3.33.06Z"
        transform="translate(0 0)"
      />
      <path
        d="M36.76,39.09h-20a2.26,2.26,0,0,0-.26,0c-.72,0-1.42-.19-1.32-1,0-.37.78-.9,1.23-.92,2.14-.11,4.28-.08,6.42-.08l33.24,0a3.32,3.32,0,0,1,1.27.07,4.53,4.53,0,0,1,1,.91,6.48,6.48,0,0,1-1,.93c-.22.14-.59,0-.89,0Z"
        transform="translate(0 0)"
      />
      <path
        d="M87.34,65.49h15.9a2.66,2.66,0,0,1,1.14.09,1.53,1.53,0,0,1,.74.91c0,.26-.32.71-.61.85a3.25,3.25,0,0,1-1.36.22H71.6c-.3,0-.68.09-.88,0a7.64,7.64,0,0,1-1.08-1c.39-.33.75-.91,1.16-.94,1.83-.1,3.67,0,5.51,0h11Z"
        transform="translate(0 0)"
      />
    </SvgIconBase>
  )
}

IconWebApp.defaultProps = {
  fillColor: "#ffffff",
  width: 100,
}

IconWebApp.propTypes = {
  fillColor: PropTypes.string,
  width: PropTypes.number,
}

export default IconWebApp
