import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Grid, Row, Col } from "react-styled-flexboxgrid"
import Base from "./Base"

const defaultProps = {
  centered: false,
}

const propTypes = {
  children: PropTypes.node.isRequired,
  centered: PropTypes.bool,
}

const SectionPrimary = styled(Base)`
  background-color: #fff;
  text-align: ${props => (props.textCentered ? "center" : "initial")};
  h1,
  h2,
  h3 {
    color: ${props => props.theme.oil};
  }
  blockquote {
    font-size: 2rem;
  }
  strong {
    color: ${props => props.theme.oil};
  }
  @media screen and (max-width: 39.9375em) {
  }
`

const Primary = props => {
  const { children, centered } = props
  return (
    <SectionPrimary textCentered={centered}>
      <Grid>
        <Row>
          <Col xs={12}>{children}</Col>
        </Row>
      </Grid>
    </SectionPrimary>
  )
}

Primary.defaultProps = defaultProps
Primary.propTypes = propTypes

export default Primary
