import React from "react"
import PropTypes from "prop-types"

import SvgIconBase from "./SvgIconBase"

const IconCode = ({ fillColor, width }) => {
  return (
    <SvgIconBase viewBox="0 0 128 72.02" width={width} fill={fillColor}>
      <path
        d="M19.11,36a16.37,16.37,0,0,1,1.37,1.06L42.37,59c2.28,2.28,2.28,4.4,0,6.69-1.65,1.65-3.29,3.31-4.95,4.94a3.93,3.93,0,0,1-6,0q-15-15-30-30a4.9,4.9,0,0,1-.77-1C-.6,37.48,0,32.79,1.8,31L31.1,1.69c2.25-2.24,4.37-2.25,6.6,0C39.32,3.28,41,4.89,42.56,6.52c2,2.06,2,4.26,0,6.33q-11,11.06-22.08,22.08C20.13,35.25,19.75,35.51,19.11,36Z"
        transform="translate(0 0)"
      />
      <path
        d="M108.63,36c-.36-.38-.66-.71-1-1L85.76,13.06c-2.35-2.35-2.35-4.43,0-6.78q2.37-2.38,4.77-4.76c2-2,4.25-2,6.24,0q14.88,14.86,29.75,29.76A4.75,4.75,0,0,1,128,34.81c0,.75,0,1.5,0,2.25a4.9,4.9,0,0,1-1.57,3.79Q111.63,55.63,96.85,70.44c-2.11,2.1-4.29,2.08-6.42,0q-2.48-2.46-4.94-5c-2-2-2-4.24,0-6.24Q96.56,48.11,107.65,37C108,36.72,108.26,36.38,108.63,36Z"
        transform="translate(0 0)"
      />
      <path
        d="M56,72c-1.29,0-2.58,0-3.87,0a4,4,0,0,1-3.92-5.09Q54,43.48,59.9,20.08,62,11.86,64,3.64C64.67,1.06,66,0,68.63,0h7c3.16,0,4.94,2.24,4.18,5.28Q71.92,37,64,68.68A4,4,0,0,1,59.76,72Z"
        transform="translate(0 0)"
      />
    </SvgIconBase>
  )
}

IconCode.defaultProps = {
  fillColor: "#ffffff",
  width: 100,
}

IconCode.propTypes = {
  fillColor: PropTypes.string,
  width: PropTypes.number,
}

export default IconCode
