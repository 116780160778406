import React from "react"
import PropTypes from "prop-types"

import styled from "styled-components"
import { Grid, Row, Col } from "react-styled-flexboxgrid"
import Base from "./Base"

const defaultProps = {
  centered: false,
}

const propTypes = {
  children: PropTypes.node.isRequired,
  centered: PropTypes.bool,
}
const SectionSecondary = styled(Base)`
  background-color: ${props => props.theme.black};
  text-align: ${props => (props.textCentered ? "center" : "initial")};
  h1,
  h2,
  h3,
  h4 {
    color: ${props => props.theme.white};
  }
  strong {
    color: ${props => props.theme.red};
  }
`

const Secondary = props => {
  const { children, centered } = props
  return (
    <SectionSecondary textCentered={centered}>
      <Grid>
        <Row>
          <Col xs={12}>{children}</Col>
        </Row>
      </Grid>
    </SectionSecondary>
  )
}

Secondary.defaultProps = defaultProps
Secondary.propTypes = propTypes

export default Secondary
