import React from "react"
import PropTypes from "prop-types"

import SvgIconBase from "./SvgIconBase"

const IconMobileMenuClose = ({ fillColor, width }) => {
  return (
    <SvgIconBase viewBox="0 0 128 128" width={width} fill={fillColor}>
      <path
        d="M128,19.59a7.69,7.69,0,0,1-2.42,5.25L107.4,43,87,63.42c-.57.58-.57.58,0,1.16q19.2,19.2,38.4,38.4a8,8,0,0,1,2.57,6.19,7.36,7.36,0,0,1-2.14,5q-5.75,5.85-11.61,11.61a7.5,7.5,0,0,1-6.48,2.09,7.86,7.86,0,0,1-4.58-2.34q-6.94-7-13.91-13.91L64.73,87.13c-.72-.72-.72-.72-1.42,0L25,125.39A8,8,0,0,1,18.91,128a7.48,7.48,0,0,1-5.17-2.17q-5.81-5.72-11.52-11.52A7.52,7.52,0,0,1,.09,107.7a7.85,7.85,0,0,1,2.32-4.53q8.49-8.46,17-16.94Q30.21,75.38,41.05,64.57c.46-.46.42-.7,0-1.13Q25.07,47.5,9.11,31.53,5.83,28.24,2.53,25A7.88,7.88,0,0,1,.07,18.23a7.47,7.47,0,0,1,2.12-4.48c3-3,6-6,8.94-9C12,3.89,12.88,3,13.79,2.14A7.67,7.67,0,0,1,20.23.08a7.37,7.37,0,0,1,4.36,2.11L33,10.6l30.2,30.2a3,3,0,0,1,.31.31c.31.41.58.38.94,0,2.49-2.52,5-5,7.5-7.52q15.49-15.5,31-31A8,8,0,0,1,109.71.05a7.39,7.39,0,0,1,4.5,2.08c3.92,3.88,7.84,7.76,11.7,11.69A7.71,7.71,0,0,1,128,19.59Z"
        transform="translate(0)"
      />
    </SvgIconBase>
  )
}

IconMobileMenuClose.defaultProps = {
  fillColor: "#15133f",
  width: 30,
}

IconMobileMenuClose.propTypes = {
  fillColor: PropTypes.string,
  width: PropTypes.number,
}

export default IconMobileMenuClose
