import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledSubTitle = styled.h4`
  color: ${ props => props.theme.curiousBlue };
  font-family: "Passion One", cursive;
  font-size: 3rem;
  display: block;
  text-align: center;
  font-weight: 100;
`

const SubTitle = props => {
  const { alternate, subtitle } = props
  return <StyledSubTitle alternate={alternate}>{subtitle}</StyledSubTitle>
}
SubTitle.defaultProps = {
  alternate: false,
}
SubTitle.propTypes = {
  alternate: PropTypes.bool,
  subtitle: PropTypes.string.isRequired,
}

export default SubTitle
